import { action, computed, observable, toJS } from "mobx";
import { MenuItem } from "primereact/components/menuitem/MenuItem";
import Collection from "../../infrastructure/CollectionHelper";
import {
  AgentTabs,
  AgentTabViewModelTypes,
} from "../../infrastructure/enum/AgentDetails";
import { Constants } from "../../infrastructure/enum/Constants";
import { Permission } from "../../infrastructure/enum/Permission";
import IMASLog from "../../infrastructure/IMASLog";
import LeadsTabContext from "../../infrastructure/LeadsTabContext";
import PageContext from "../../infrastructure/PageContext";
import RouteList from "../../infrastructure/RouteList";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import UserContext from "../../infrastructure/UserContext";
import Utils from "../../infrastructure/Utils";
import ViewModel from "../../infrastructure/ViewModel";
import { ActionLogModel } from "../../services/IdentityService";
import {
  AgentProfileLoginModel,
  AgentReceivePaperStatementModel,
  AgentSummaryModel,
  CreateUpdateAlertRequestModel,
  ListItem,
  MarketingPreferencesModel,
} from "../../services/ProducerService";
import IdentityStore from "../../store/IdentityStore";
import ProducerStore from "../../store/ProducerStore";
import { TestViewModel } from "../TestViewModel";
import { routes } from "./../../router";
import { AppInlineProfileViewModel } from "./../AppInlineProfileViewModel";
//import { AddAgentDebtViewModel } from "./AddAgentDebtViewModel";
import { ContractUpdateListener } from "./contracts/AddEditContractViewModel";
import { AddNoteViewModel } from "./AddNoteViewModel";
import { AddTaskViewModel } from "./AddTaskViewModel";
import { AgentAccountViewModel } from "./AgentAccountViewModel";
import { AgentAppointmentsViewModel } from "./AgentAppointmentsViewModel";
import { ManageRTSViewModel } from "./../../views/agents/managerts/ManageRTSViewModel";
import { AgentAutoAppointmentsViewModel } from "./AgentAutoAppointmentsViewModel";
import {
  AgentLicensesViewModel,
  AgentSummaryListener,
} from "./AgentContractsViewModel";
import { AgentEmailViewModel } from "./AgentEmailViewModel";
import { AgentNotesViewModel } from "./AgentNotesViewModel";
import { AgentPoliciesViewModel } from "./AgentPoliciesViewModel";
import { AgentProfileViewModel } from "./AgentProfileViewModel";
import {
  AgentSummaryViewModel,
  UpdateAgentListener,
} from "./AgentSummaryViewModel";
import { AgentTasksViewModel } from "./AgentTasksViewModel";
import { ContractDetailsViewModel } from "./contracts/ContractDetailsViewModel";
import { AddEditContractViewModel } from "./contracts/AddEditContractViewModel";
import {
  AddDocumentListener,
  AddDocViewModel,
} from "./documents/AddDocViewModel";
import { AgentDocsViewModel } from "./documents/AgentDocsViewModel";
import { EditDocViewModel } from "./documents/EditDocViewModel";
import { AddEditLevelViewModel } from "./levels/AddEditLevelViewModel";
import { AddSupplyItemViewModel } from "./orders/AddSupplyItemViewModel";
import {
  AddSupplyOrderViewModel,
  UpdateAgentOrderListener,
} from "./orders/AddSupplyOrderViewModel";
import { AgentOrdersViewModel } from "./orders/AgentOrdersViewModel";
import { OrderDetailsViewModel } from "./orders/OrderDetailsViewModel";
import { AddEditPaymentPlanViewModel } from "./paymentplans/AddEditPaymentPlanViewModel";
import { AddEditPolicyViewModel } from "./policies/AddEditPolicyViewModel";
import { PolicyDetailsViewModel } from "./PolicyDetailsViewModel";
import { ProductionViewModel } from "./ProductionViewModel";
import { StateLicensesAddViewModel } from "./statelicenses/StateLicensesAddViewModel";
import { StateLicensesEditViewModel } from "./statelicenses/StateLicensesEditViewModel";
import { StateLicensesListViewModel } from "./statelicenses/StateLicensesListViewModel";
import { TripPointsViewModel } from "./TripPointsViewModel";
import { WorkflowViewModel } from "./WorkflowViewModel";
import { toast } from "react-toastify";
import { closeToasterButtonTemplate } from "../../views/components/controls/IMASTemplates";
export class AgentDetailsTabViewModel
  implements
    ViewModel,
    UpdateAgentListener,
    UpdateAgentOrderListener,
    AddDocumentListener,
    AgentSummaryListener,
    ContractUpdateListener
{
  updateStatus = async (status: string) => {
    this.status = status;
  };
  updateSummary = async () => {
    await this.loadAgentSummary();
    this.AgentSummaryViewModel.loadAgentSummary();
  };
  updateDnxOptions = async () => {
    await this.checkDnxOption();
  };
  documentAddedToWorkflow = async () => {
    this.agentWorkflowCount = this.agentWorkflowCount + 1;
    this.agentTabItems[11].label = `Workflow (${this.agentWorkflowCount})`;
  };

  updateRating(badRating: boolean, message: string): void {
    this.badRating = badRating;
    this.badRatingMessage = message;
  }
  updateAgentDebtInfo(
    isAgentOwes: boolean,
    debtLevel: string,
    debtDescription: string
  ): void {
    this.isAgentOwesLevel = isAgentOwes;
    this.agentDebtLevel = debtLevel;
    this.agentDebtDescription = debtDescription;
  }
  updateAgentInfo(
    name: string,
    location: string,
    status: string,
    enableLogin: boolean,
    receivePaperStatement: boolean,
    nameForSMSTitle: string,
    npn: string
  ): void {
    this.agentName = name;
    this.status = status;
    this.location = location;
    RouteParamDetails.agentLocation = location;
    this.enableLogin = enableLogin;
    this.receivePaperStatement = receivePaperStatement;
    this.marketPreferenceDialogTitle = "Marketing Preferences - " + name;
    this.nameForSMSTitle = nameForSMSTitle;
    this.npn = npn;
  }

  updateAgenOrdertInfo(action: string): void {
    this.agentOrdersCount =
      action === "add" ? this.agentOrdersCount + 1 : this.agentOrdersCount - 1;
  }
  updateDefaultStateOnHeaderInfo(): void {
    this.updateDefaultAgentStateOnHeader();
  }
  @action updateDefaultAgentStateOnHeader = async () => {
    if (this.agentId && this.agentId !== undefined && this.agentId !== null) {
      var result = await ProducerStore.getAgentSummary(+this.agentId);
      if (result) {
        this.agentCity = result.city ? result.city : "";
        this.agentState = result.state ? result.state : "";
      }
    }
  };
  @observable nameForSMSTitle: string = "";

  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action resetView() {
    this.selectedKey = AgentTabs.Summary;
    this.SelectedViewModel = AgentTabViewModelTypes.Summary;
  }

  @observable AppInlineProfileViewModel: AppInlineProfileViewModel | undefined;

  @action setAppInlineProfileViewModel(
    viewModel: AppInlineProfileViewModel | undefined
  ) {
    this.AppInlineProfileViewModel = viewModel;
  }

  @action Load = async (id: string) => {
    window.scrollTo(0, 0);
    this.isLoading = false;
    this.isSuccess = false;
    this.isUpdateAlert = false;
    this.isCreateAlert = false;
    this.isClearAlert = false;

    this.isShowCreateToast = false;
    localStorage.removeItem("agentManageAlertAdd");
    this.isException = false;
    this.exceptionMessage = "";
    this.alertData = "";
    this.hasAlert = false;
    this.response = "";
    this.isLoadingComplete = false;
    this.policyId = RouteParamDetails.policyId;
    this.noteId = RouteParamDetails.noteId;
    this.contractId = RouteParamDetails.contractId;
    this.taskId = RouteParamDetails.taskId;
    this.docId = RouteParamDetails.docId;
    this.paymentPlanId = RouteParamDetails.paymentPlanId;
    this.debtId = RouteParamDetails.debtId;
    this.isAgentOwesLevel = false;
    this.agentDebtLevel = "";
    this.agentDebtDescription = "";
    this.AgentSummaryViewModel.setListener(this);
    this.AgentLicensesViewModel.setListener(this);
    this.AgentLicensesViewModel.setDnxListener(this);
    this.AddNoteViewModel.setListener(this);
    this.AddDocViewModel.setListener(this);
    this.EditDocViewModel.setListener(this);
    this.AddEditContractViewModel.setListener(this);
    this.AddSupplyOrderViewModel.setListener(this);
    this.tabsDisable = true;
    if (this.selectedTabIndex == 0 || this.selectedKey == "Summary") {
      // this.getAlertByAgentId(parseInt(id));
    }
    this.resetView();
    if (
      this.agentId !== id ||
      PageContext.isFromEdit ||
      PageContext.isMovedToDataEntry
    ) {
      this.agentId = id;
      localStorage.setItem(Constants.currentAgentId, this.agentId);
      if (
        localStorage.getItem("SumitFromWorkFlow") == null ||
        localStorage.getItem("SumitFromWorkFlow") == ""
      ) {
        await this.loadAgentSummary();
      } else {
        //this.handleTabClick(11,.Documents");
        this.loadAgentInfoForDocSubmit();
      }
      PageContext.setIsLoadingVisible(false);
      await this.loadProfilePic();
      this.checkDnxOption();
      PageContext.setIsFromEdit(false);
      PageContext.setIsMovedToDataEntry(false);
      this.agentTabItems[11].label = `Workflow (${this.agentWorkflowCount})`;
      this.agentTabItems[6].label = `Orders (${this.agentOrdersCount})`;
    }
    this.isLoadingComplete = true;
    this.tabsDisable = false;
  };

  @action loadProfilePic = async () => {
    try {
      if (!Utils.hasUserPermission(Permission.ViewAgentProfilePicture)) {
        this.profilePic = "assets/layout/images/profile-default.png";
        return;
      }
      if (this.agentId) {
        setTimeout(() => {
          PageContext.setIsLoadingVisible(false);
        }, 3000);
        var result = await ProducerStore.getAgentProfilePic(+this.agentId);
        if (result) {
          this.isSuccess = false;
          this.isException = false;
        }
        if (
          this.isIntegrityPlatformPartner &&
          this.isIntegrityPlatformPartner === true
        ) {
          this.profilePic =
            "assets/layout/images/Integrity_PlatformIndicator_Logo.png";
        } else {
          if (result && result.imageData) {
            this.profilePic = "data:image/jpeg;base64," + result.imageData;
          } else {
            this.profilePic = "assets/layout/images/profile-default.png";
          }
        }
      }
    } catch (e) {
      this.profilePic = "assets/layout/images/profile-default.png";
    }
  };
  @action loadAgentInfoForDocSubmit = async () => {
    if (this.agentId) {
      this.profilePic = "assets/layout/images/profile-default.png";
      this.updateAgentInfo("", "", "", false, false, "", "");
      this.isLimitsOnCommunication = false;
      var result = await ProducerStore.getAgentSummary(+this.agentId);
      this.agentWorkflowCount =
        result && result.workflowCount ? +result.workflowCount : 0;
      this.agentOrdersCount =
        result && result.orderCount ? +result.orderCount : 0;
      this.isIntegrityPlatformPartner =
        result.isIntegrityPlatformPartner || false;
      this.agentTabItems[11].label = `Workflow (${this.agentWorkflowCount})`;
      this.setAgentInfo(result);
    }
  };
  @action loadAgentSummary = async () => {
    try {
      if (this.agentId) {
        this.profilePic = "assets/layout/images/profile-default.png";
        // this.AgentSummaryViewModel.setAgentSummaryResponse(null);
        // this.updateAgentInfo("", "", "", false, false, "", "");
        var result = await ProducerStore.getAgentSummary(+this.agentId);
        this.AgentSummaryViewModel.setAgentSummaryResponse(result);
        this.agentWorkflowCount =
          result && result.workflowCount ? +result.workflowCount : 0;
        this.agentOrdersCount =
          result && result.orderCount ? +result.orderCount : 0;
        this.isIntegrityPlatformPartner =
          result.isIntegrityPlatformPartner || false;
        this.agentTabItems[11].label = `Workflow (${this.agentWorkflowCount})`;
        this.setAgentInfo(result);
        if (
          result.leadSourceId === 79 &&
          !UserContext.hasPermmission(Permission.ViewPreNeedAgents)
        ) {
          routes.agentSearch.replace();
          return;
        }
        if (
          result.isHiddenAgentProfile &&
          !UserContext.hasPermmission(Permission.ViewHiddenAgentProfile)
        ) {
          routes.agentSearch.replace();
          return;
        }
        if (!result.isUserCanSeeThisAgent) {
          routes.agentSearch.replace();
          return;
        }
      }
    } catch (e) {}
  };
  @action checkDnxOption = async () => {
    try {
      if (this.agentId) {
        var result = await ProducerStore.checkDNXOption(+this.agentId);
        this.isLimitsOnCommunication = result;
      }
    } catch (e) {}
  };
  @action setAgentInfo(result: AgentSummaryModel) {
    localStorage.setItem("isAgency", result.isAgency ? "true" : "false");
    let displayCity = result.city ? result.city : "";
    let displayState = result.state ? result.state : "";
    let location = "";
    let name = "";
    if (displayCity && displayState)
      location = displayCity + "," + displayState;
    let nickName = result.nickName ? " ('" + result.nickName + "')" : "";
    RouteParamDetails.firstName = result.name ? result.name : "";
    name = "" + result.name + nickName;
    this.agentNameOriginal = result.name ? result.name : "";
    var fullName = result.name || "";
    name = name + " (" + this.agentId + ")";
    let status = result.statusText ? result.statusText : "";
    RouteParamDetails.agentName = name;
    let enableLogin = result.enableLogin ? result.enableLogin : false;
    let receivePaperStatement = result.receivePaperStatement
      ? result.receivePaperStatement
      : false;
    let npn = result.npn;
    this.agentCity = result.city ? result.city : "";
    this.agentState = result.state ? result.state : "";
    this.updateAgentInfo(
      name,
      location,
      status,
      enableLogin,
      receivePaperStatement,
      fullName,
      npn
    );
    this.updateRating(
      result.badRatingVisible ? result.badRatingVisible : false,
      result.badRatingText ? result.badRatingText : ""
    );
  }
  Route = async (currentRoute: RouteList): Promise<void> => {
    if (
      currentRoute.name == "agentSummary" ||
      currentRoute.name == "agentDetails"
    ) {
      this.getAlertByAgentId(parseInt(currentRoute.params.agentId));
    }
    this.handleRoute(currentRoute);
  };
  @observable isIntegrityPlatformPartner: boolean = false;
  @observable tabsDisable: boolean = false;
  @observable exceptionMessage: any;
  @observable response: any = "";
  @observable isException: boolean = false;
  @observable isSuccess: boolean = false;
  @observable isAgentOwesLevel: boolean = false;
  @observable agentDebtLevel: string = "";
  @observable agentDebtDescription: string = "";
  @observable isLoading: boolean = false;
  @observable isLoadingComplete: boolean = false;
  @observable tabValue: number = 0;
  @observable policyId: number = 0;
  @observable noteId: number = 0;
  @observable taskId: number = 0;
  @observable docId: number = 0;
  @observable paymentPlanId: number = 0;
  @observable contractId: number = 0;
  @observable debtId: number = 0;
  @observable selectedKey: string | undefined = "";
  @observable selectedTabIndex?: number = 0;
  @observable agentName: string | undefined = "";
  @observable status: string | undefined = "";
  @observable location: string | undefined = "";
  @observable profilePic: string | undefined =
    "assets/layout/images/profile-default.png";
  @observable profilePicPartnerlogo: string | undefined =
    "assets/layout/images/IntegrityPlatformPartner.png";
  @observable agentId: string | undefined = "";
  @observable badRating: boolean = false;
  @observable badRatingMessage: string = "";
  @observable TestViewModel: TestViewModel = new TestViewModel();
  @observable
  AgentSummaryViewModel: AgentSummaryViewModel = new AgentSummaryViewModel();
  @observable
  AgentNotesViewModel: AgentNotesViewModel = new AgentNotesViewModel();
  AgentEmailViewModel: AgentEmailViewModel = new AgentEmailViewModel();
  @observable ProductionViewModel: ProductionViewModel =
    new ProductionViewModel();
  @observable StateLicensesListViewModel: StateLicensesListViewModel =
    new StateLicensesListViewModel();
  @observable StateLicensesAddViewModel: StateLicensesAddViewModel =
    new StateLicensesAddViewModel();
  @observable StateLicensesEditViewModel: StateLicensesEditViewModel =
    new StateLicensesEditViewModel();
  @observable AddNoteViewModel: AddNoteViewModel = new AddNoteViewModel();
  @observable
  PolicyDetailsViewModel: PolicyDetailsViewModel = new PolicyDetailsViewModel();
  @observable
  AddEditPolicyViewModel: AddEditPolicyViewModel = new AddEditPolicyViewModel();
  @observable
  AgentPoliciesViewModel: AgentPoliciesViewModel = new AgentPoliciesViewModel();
  @observable
  AgentAppointmentsViewModel: AgentAppointmentsViewModel =
    new AgentAppointmentsViewModel();
  @observable
  AgentAutoAppointmentsViewModel: AgentAutoAppointmentsViewModel =
    new AgentAutoAppointmentsViewModel();
  @observable
  ManageRTSViewModel: ManageRTSViewModel = new ManageRTSViewModel();
  @observable
  AgentLicensesViewModel: AgentLicensesViewModel = new AgentLicensesViewModel();
  @observable
  AgentTasksViewModel: AgentTasksViewModel = new AgentTasksViewModel();
  @observable
  AddEditContractViewModel: AddEditContractViewModel =
    new AddEditContractViewModel();
  @observable
  AgentOrdersViewModel: AgentOrdersViewModel = new AgentOrdersViewModel();
  @observable
  OrderDetailsViewModel: OrderDetailsViewModel = new OrderDetailsViewModel();
  @observable
  AddSupplyOrderViewModel: AddSupplyOrderViewModel =
    new AddSupplyOrderViewModel();
  @observable
  ContractDetailsViewModel: ContractDetailsViewModel =
    new ContractDetailsViewModel();
  @observable AddTaskViewModel: AddTaskViewModel = new AddTaskViewModel();
  @observable
  TripPointsViewModel: TripPointsViewModel = new TripPointsViewModel();
  @observable
  AddSupplyItemViewModel: AddSupplyItemViewModel = new AddSupplyItemViewModel();
  @observable AgentDocsViewModel: AgentDocsViewModel = new AgentDocsViewModel();
  @observable AddDocViewModel: AddDocViewModel = new AddDocViewModel();
  @observable EditDocViewModel: EditDocViewModel = new EditDocViewModel();
  @observable WorkflowViewModel: WorkflowViewModel = new WorkflowViewModel();
  @observable
  AgentAccountViewModel: AgentAccountViewModel = new AgentAccountViewModel();
  @observable
  AgentProfileViewModel: AgentProfileViewModel = new AgentProfileViewModel();
  // @observable
  // AddAgentDebtViewModel: AddAgentDebtViewModel = new AddAgentDebtViewModel();
  @observable
  AddEditPaymentPlanViewModel: AddEditPaymentPlanViewModel =
    new AddEditPaymentPlanViewModel();
  @observable AddEditLevelViewModel: AddEditLevelViewModel =
    new AddEditLevelViewModel();
  @observable SelectedViewModel: keyof typeof AgentTabViewModelTypes =
    AgentTabViewModelTypes[AgentTabViewModelTypes.Summary];
  @observable SelectedBaseViewModel: ViewModel | undefined;
  @observable isLimitsOnCommunication: boolean = false;
  @observable marketPreferenceDialogTitle: string = "Marketing Preferences";
  @observable marketPrefernceDialogVisible: boolean = false;
  @observable health: boolean = false;
  @observable lifeAndAnnuities: boolean = false;
  @observable finalExpense: boolean = false;
  @observable longTermCare: boolean = false;
  @observable agentWorkflowCount: number = 0;
  @observable agentOrdersCount: number = 0;
  @observable enableLogin: boolean = false;
  @observable receivePaperStatement: boolean = false;
  @observable npn: string = "";
  @observable agentCity: string | undefined = "";
  @observable agentNameOriginal: string | undefined = "";
  @observable agentState: string | undefined = "";

  @observable alertMessage: string = "";
  @observable alertMessageTmp: string = "";
  @observable showToolTip: boolean = false;
  @observable isUpdateAlert: boolean = false;
  @observable isCreateAlert: boolean = false;
  @observable isClearAlert: boolean = false;
  @observable isMaxLenghtExceed: boolean = false;
  @observable characterCount: number = 0;
  @observable isShowCreateToast: boolean = false;
  @observable isAlertsViewVisible: boolean = false;
  @observable hasAlert: boolean = false;
  @observable alertData: any;
  @observable timer: any;
  @observable isLoadRecentProfiles: boolean = true;

  @observable breadCrumbMenuItems: MenuItem[] = [
    {
      label: "Agents",
      command: () => {
        this.goBackToAgents();
      },
    },
  ];
  @observable isAlertsVisible: boolean = false;

  @action goToCreateLead = () => {
    LeadsTabContext.setIsGotoLeadFromAgent(true);
    routes.addAgentLead.push();
  };

  @action setAlertsVisibility = async (value: boolean) => {
    if (value) {
      this.isUpdateAlert = false;
      this.isCreateAlert = false;
      this.isClearAlert = false;

      localStorage.removeItem("agentManageAlertAdd");
      this.isShowCreateToast = false;
      let response = await ProducerStore.getAlertByAgentId(this.agentId);
      if (response && typeof response == "object" && response.alert !== null) {
        this.isAlertsViewVisible = true;
        this.alertData = response;
        this.hasAlert = true;
      } else {
        if (
          response &&
          typeof response == "object" &&
          response.createdBy !== null
        ) {
          this.hasAlert = true;
          this.characterCount = 0;
          this.alertMessage = "";
          this.alertData = "";
        } else {
          this.hasAlert = false;
        }
        this.alertMessage = "";
        this.isAlertsVisible = value;
      }
      if (this.hasAlert) {
        this.handleEditAlert();
      }
    } else {
      this.alertMessage = "";
      this.isAlertsVisible = value;
    }
  };

  @action handleEditAlert = () => {
    this.alertMessage = this.alertData && this.alertData.alert;
    this.isAlertsVisible = true;
    this.characterCount =
      this.alertData &&
      this.alertData.alert &&
      this.alertData.alert.replace(/<[^>]+>/g, "").length;
    this.isMaxLenghtExceed = this.characterCount > 500;
    this.isAlertsViewVisible = false;
  };

  @action getAlertByAgentId = async (agentId: any) => {
    let response = await ProducerStore.getAlertByAgentId(agentId);
    if (
      response &&
      typeof response == "object" &&
      response.alert !== null &&
      response.alert !== ""
    ) {
      this.isAlertsViewVisible = true;
      this.alertData = response;
      this.hasAlert = true;
    } else {
      this.hasAlert = false;
    }
  };

  @action saveAlert = async () => {
    this.isLoading = true;
    localStorage.setItem("agentManageAlertAdd", "add");
    try {
      var dto: CreateUpdateAlertRequestModel = {
        alert: this.alertMessage,
        agentId: this.agentId ? +this.agentId : undefined,
      };
      let tmpVar =
        (this.characterCount > 0 &&
          this.alertMessage &&
          this.alertMessage.replace(/<[^>]+>/g, "")) ||
        "";
      if (this.characterCount > 0 && !tmpVar.replace(/\s/g, "").length) {
        this.isClearAlert = true;
        dto.alert = "";
        this.characterCount = 0;
      }
      if (this.hasAlert) {
        await ProducerStore.updateAlert(dto);
        if (this.characterCount < 1) {
          dto.alert = "";
          this.isClearAlert = true;
        } else {
          this.isUpdateAlert = true;
        }
      } else {
        await ProducerStore.createAlert(dto);
        this.isCreateAlert = true;
      }
      this.isShowCreateToast = true;
      this.isAlertsVisible = false;
      this.isLoading = false;
    } catch (e: any) {
      e.text().then((_responseText: any) => {
        let _title = JSON.parse(_responseText).title;
        this.showErrorMessageinToaster(`${_title}`);
      });
      this.isLoading = false;
      PageContext.setIsMessageVisible(false);
      IMASLog.log("exception: " + e);
    }
  };

  @action hideValidationToaster = () => {
    toast.dismiss("validation-toaster");
  };

  showErrorMessageinToaster(message: string) {
    toast.warning(message, {
      position: toast.POSITION.TOP_RIGHT,
      closeOnClick: false,
      closeButton: closeToasterButtonTemplate(this.hideValidationToaster),
      className: "validation-toaster website",
      draggable: false,
      toastId: "validation-toaster",
      autoClose: 7000,
    });
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      this.hideValidationToaster();
    }, 7000);
  }

  @action setalertChange(value: string) {
    localStorage.removeItem("agentManageAlertAdd");
    this.isShowCreateToast = false;
    if (value && value.length > 0) {
      this.characterCount = value.replace(/<[^>]+>/g, "").length;
      this.isMaxLenghtExceed = this.characterCount > 500;
      this.alertMessage = value;
    } else {
      this.isMaxLenghtExceed = false;
      this.characterCount = 0;
    }
    let isEmptyAlertCheck = this.alertMessage
      .replace(/<[^>]+>/g, "")
      .replace(/\s/g, "").length;
    if (isEmptyAlertCheck < 1) {
      this.characterCount = 0;
    }
  }

  @action AgentPortalLogin = async (enableLogin: boolean) => {
    try {
      var dto: AgentProfileLoginModel = {
        agentId: this.agentId ? +this.agentId : undefined,
        enableLogin: enableLogin,
      };
      await ProducerStore.AgentPortalLogin(dto);
      this.isLoading = false;
      this.enableLogin = !this.enableLogin;
      setTimeout(async () => {
        this.loadProfilePic();
      }, Utils.timeDelay_Success());
    } catch (error: any) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
    }
  };

  @action ReceivePaperStatement = async (receivePaperStatement: boolean) => {
    try {
      var dto: AgentReceivePaperStatementModel = {
        agentId: this.agentId ? +this.agentId : undefined,
        receivePaperStatement: receivePaperStatement,
      };
      await ProducerStore.ReceivePaperStatement(dto);
      this.isLoading = false;
      this.receivePaperStatement = !this.receivePaperStatement;
      this.AgentSummaryViewModel.setReceivePaperStatement(
        this.receivePaperStatement
      );
      setTimeout(async () => {
        this.loadProfilePic();
      }, 300);
    } catch (error: any) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
    }
  };

  @action setMarketPrefereneDialogVisible(value: boolean) {
    this.marketPrefernceDialogVisible = value;
    if (value === true) {
      this.setHealth(false);
      this.setFinalExpense(false);
      this.setLifeAndAnnuities(false);
      this.setLongTermCare(false);
      this.loadAgentEmails();
    }
  }

  @observable availableEmails = new Collection<{
    label: string;
    value: string;
  }>();
  @observable preferenceEmailAddress: string = "";
  @observable agentEmails: ListItem[] | null = null;

  @action loadAgentEmails = async () => {
    try {
      this.isSuccess = false;
      this.isException = false;
      this.exceptionMessage = "";
      this.agentEmails = [];
      this.agentEmails = await ProducerStore.GetMarketerEmails(
        this.agentId ? +this.agentId : 0
      );
      if (this.agentEmails != null && this.agentEmails.length > 0) {
        this.availableEmails.values = [];
        this.agentEmails.forEach((obj) => {
          var data = {
            label: obj.value ? obj.value : "",
            value: obj.text ? obj.text : "",
          };
          this.availableEmails.values.push(data);
          this.preferenceEmailAddress =
            obj.selected === true ? (obj.value ? obj.value : "") : "";
        });

        if (this.preferenceEmailAddress === "") {
          this.preferenceEmailAddress = this.availableEmails.values
            ? this.availableEmails.values[0].value
            : "";
          if (this.preferenceEmailAddress !== "") {
            this.setPreferredEmail(this.preferenceEmailAddress);
          }
        }
      }
      this.isLoading = false;
    } catch (error: any) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
    }
  };

  @action getMarketingPreferences = async () => {
    try {
      this.isSuccess = false;
      this.isException = false;
      this.exceptionMessage = "";
      var result = await ProducerStore.getMarketingPreferences(
        this.preferenceEmailAddress ? this.preferenceEmailAddress : ""
      );
      if (result && result != null) {
        this.health = result.health ? result.health : false;
        this.lifeAndAnnuities = result.lifeAnnuities
          ? result.lifeAnnuities
          : false;
        this.finalExpense = result.funeralTrustOrFinalExpense
          ? result.funeralTrustOrFinalExpense
          : false;
        this.longTermCare = result.longTermCare ? result.longTermCare : false;
      }
      this.isLoading = false;
    } catch (error: any) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
    }
  };

  @action savePreferences = async () => {
    try {
      var dto: MarketingPreferencesModel = {
        health: this.health,
        lifeAnnuities: this.lifeAndAnnuities,
        funeralTrustOrFinalExpense: this.finalExpense,
        longTermCare: this.longTermCare,
        agentId: this.agentId ? +this.agentId : 0,
        email: this.preferenceEmailAddress,
      };
      var result = await ProducerStore.savePreferences(dto);
      if (result != null) {
        setTimeout(async () => {
          this.isSuccess = false;
          this.setMarketPrefereneDialogVisible(false);
        }, Utils.timeDelay_Success());
      }
    } catch (error: any) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
    }
  };

  @action setHealth(value: boolean) {
    this.health = value;
  }
  @action setLifeAndAnnuities(value: boolean) {
    this.lifeAndAnnuities = value;
  }
  @action setFinalExpense(value: boolean) {
    this.finalExpense = value;
  }
  @action setLongTermCare(value: boolean) {
    this.longTermCare = value;
  }
  @action setPreferredEmail(value: string) {
    this.preferenceEmailAddress = value;
    this.getMarketingPreferences();
  }

  @action onAddNoteClick = () => {
    routes.addNote.push({
      agentId: "" + this.agentId,
    });
  };
  @action onAddStateLicenseClick = () => {
    routes.addAgentStateLicense.push({
      agentId: "" + this.agentId,
    });
  };
  @action onAddTaskClick = () => {
    routes.addTask.push({
      agentId: "" + this.agentId,
    });
  };
  @action onAddContractClick = () => {
    routes.addContract.push({
      agentId: "" + this.agentId,
    });
  };

  @action showConractDetails = (value: string) => {
    routes.contractDetails.push({
      agentId: "" + this.agentId,
      contractId: this.AgentLicensesViewModel.contractId,
    });
  };
  @action showDocument = (value: string) => {};
  @action showOrder = (value: number) => {
    routes.orderDetails.push({
      agentId: "" + this.agentId,
      orderId: this.AgentOrdersViewModel.orderId,
    });
  };
  @action editOrder = (value: number) => {
    routes.editSupplyOrder.push({
      agentId: "" + this.agentId,
      orderId: value,
    });
  };
  @action addSupplyItem = (value: string) => {
    routes.addSupplyItem.push({
      agentId: "" + this.agentId,
    });
  };
  // @action addAgentDebt = () => {
  //   routes.addAgentDebt.push({
  //     agentId: "" + this.agentId,
  //   });
  // };
  // @action editAgentDebt = () => {
  //   routes.editAgentDebt.push({
  //     agentId: "" + this.agentId,
  //     debtId: this.AgentAccountViewModel.debtId,
  //   });
  // };
  @action addOrder = () => {
    routes.addSupplyOrder.push({
      agentId: "" + this.agentId,
    });
  };
  @action addDocument = () => {
    routes.addDoc.push({
      agentId: "" + this.agentId,
    });
  };
  @action editDocument = () => {
    routes.editDoc.push({
      agentId: "" + this.agentId,
      docId: this.AgentDocsViewModel.docId,
    });
  };
  @action addTask = (value: string) => {
    routes.addTask.push({
      agentId: "" + this.agentId,
    });
  };

  @action editTask = async () => {
    routes.editTask.push({
      agentId: "" + this.agentId,
      taskId: this.AgentTasksViewModel.taskId,
    });
  };

  @action editStateLicense = async () => {
    routes.editAgentStateLicense.push({
      agentId: "" + this.agentId,
      Id: this.StateLicensesListViewModel.selectedId,
    });
  };
  @action editConractDetails = (value: string) => {
    switch (value) {
      case "addPaymentPlan":
        routes.addPaymentPlan.push({
          agentId: "" + this.agentId,
          contractId: +this.contractId,
        });
        break;
      case "editPaymentPlan":
        routes.editPaymentPlan.push({
          agentId: "" + this.agentId,
          contractId: +this.contractId,
          paymentPlanId: this.ContractDetailsViewModel.selectedPaymentPlanId,
        });
        break;
      default:
        routes.addContract.push({
          agentId: "" + this.agentId,
        });
    }
  };
  @action editContractPaymentplanDetails = (value: string) => {
    switch (value) {
      case "addPaymentPlan":
        routes.editContractAddPaymentPlan.push({
          agentId: "" + this.agentId,
          contractId: +this.contractId,
        });
        break;
      case "editPaymentPlan":
        routes.editContractEditPaymentPlan.push({
          agentId: "" + this.agentId,
          contractId: +this.contractId,
          paymentPlanId: this.AddEditContractViewModel.selectedPaymentPlanId,
        });
        break;
      default:
        routes.addContract.push({
          agentId: "" + this.agentId,
        });
    }
  };
  @action goToCreditCheckHistory() {
    routes.agentCreditCheckHistory.push({
      agentId: "" + this.agentId,
    });
  }
  @action goToNotesTab = (
    id: string,
    param: string,
    divisionId: number | undefined
  ) => {
    IMASLog.log("values id: " + id + " param = " + param);

    switch (param) {
      case "note":
        routes.agentNotesByUser.push({
          agentId: "" + this.agentId,
          userId: +id,
        });
        break;
      case "hierarchies":
        RouteParamDetails.notesUserId = 0;
        routes.agentSummary.push({
          agentId: "" + id,
        });
        break;
      case "marketers":
        RouteParamDetails.notesUserId = 0;
        if (this.agentId && divisionId) {
          routes.agentContractsMarketer.push({
            agentId: "" + this.agentId,
            marketerId: +id,
            divisionId: divisionId,
          });
        }
        break;
    }
  };

  @action viewPolicyClick = (actionClick: string) => {
    switch (actionClick) {
      case "edit":
        window.open(
          "/agents/" +
            this.agentId +
            "/policies/edit/" +
            this.AgentPoliciesViewModel.policyId,
          "_blank"
        );
        break;
      case "add":
        break;
      default:
        window.open(
          "/agents/" +
            this.agentId +
            "/policies/details/" +
            this.AgentPoliciesViewModel.policyId,
          "_blank"
        );
        break;
    }
  };
  @action editNote = () => {
    routes.editAgentNote.push({
      agentId: "" + this.agentId,
      noteId: this.AgentNotesViewModel.noteId,
    });
  };

  @action goBackToAgents = () => {
    this.breadCrumbMenuItems.pop();
  };
  @action editAgent = async () => {
    routes.editAgent.push({
      agentId: "" + this.agentId,
    });
  };
  @action goBackToHome = () => {
    this.breadCrumbMenuItems.pop();
    routes.home.replace();
  };

  @action updateTabValue(value: number) {
    this.tabValue = value;
  }
  @action goToSummaryPage() {
    this.selectedKey = AgentTabs.Summary;
    this.SelectedViewModel = AgentTabViewModelTypes.Summary;
    this.SelectedBaseViewModel = this.AgentSummaryViewModel;
    if (this.agentId) {
      this.AgentSummaryViewModel.load(+this.agentId);
    }
  }
  @action goToAddContract() {
    this.selectedKey = AgentTabs.Contracts;
    this.SelectedViewModel = AgentTabViewModelTypes.AddContract;
    this.SelectedBaseViewModel = this.AddEditContractViewModel;
    if (this.agentId && !localStorage.getItem("keepContractDetails")) {
      this.AddEditContractViewModel.Load(+this.agentId, this.contractId);
    } else {
      //this.AddEditContractViewModel.ProductDetailsViewModel.Load(
      //  this.agentId ? +this.agentId : 0,
      //  this.contractId
      //);
      localStorage.removeItem("keepContractDetails");
    }
  }

  @action loadAgentNotesTab() {
    this.selectedKey = AgentTabs.Notes;
    this.AgentNotesViewModel.noRecordsFound();
    this.SelectedViewModel = AgentTabViewModelTypes.Notes;
    this.SelectedBaseViewModel = this.AgentNotesViewModel;
    if (this.agentId) this.AgentNotesViewModel.load(+this.agentId);
  }
  @action handleRoute(currentRoute: RouteList) {
    let name = currentRoute.name;
    let routeParamObj = JSON.parse(JSON.stringify(currentRoute.params));
    switch (name) {
      case routes.agentDetails.name:
      case routes.agentSummary.name:
        this.goToSummaryPage();
        break;
      case routes.agentNotes.name:
        RouteParamDetails.notesUserId = 0;
        this.loadAgentNotesTab();
        break;
      case routes.agentNotesByUser.name:
        RouteParamDetails.notesUserId = routeParamObj.userId;
        this.loadAgentNotesTab();
        break;
      case routes.addNote.name:
        this.selectedKey = AgentTabs.Notes;
        this.SelectedViewModel = AgentTabViewModelTypes.AddNote;
        this.SelectedBaseViewModel = this.AddNoteViewModel;
        if (this.agentId) this.AddNoteViewModel.Load(+this.agentId, undefined);
        break;
      case routes.editAgentNote.name:
        this.selectedKey = AgentTabs.Notes;
        this.SelectedViewModel = AgentTabViewModelTypes.EditAgentNote;
        this.SelectedBaseViewModel = this.AddNoteViewModel;
        if (this.agentId)
          this.AddNoteViewModel.Load(+this.agentId, this.noteId);
        break;
      case routes.addContract.name:
        this.selectedKey = AgentTabs.Contracts;
        this.SelectedViewModel = AgentTabViewModelTypes.AddContract;
        this.SelectedBaseViewModel = this.AddEditContractViewModel;
        if (this.agentId)
          this.AddEditContractViewModel.Load(+this.agentId, undefined);
        break;

      case routes.replaceContract.name:
      case routes.editContract.name:
        this.goToAddContract();
        break;
      case routes.policyDetails.name:
        this.selectedKey = AgentTabs.Policies;
        this.SelectedViewModel = AgentTabViewModelTypes.ViewPolicy;
        this.SelectedBaseViewModel = this.PolicyDetailsViewModel;
        var tabIndex = routeParamObj.tabIndex;
        if (tabIndex == undefined) {
          tabIndex = 0;
        }
        this.PolicyDetailsViewModel.Load(this.policyId, tabIndex);
        break;

      case routes.editAgentPolicy.name:
        this.selectedKey = AgentTabs.Policies;
        this.SelectedViewModel = AgentTabViewModelTypes.AddEditPolicy;
        this.SelectedBaseViewModel = this.AddEditPolicyViewModel;
        if (this.agentId)
          this.AddEditPolicyViewModel.Load(+this.agentId, this.policyId, "");
        break;
      case routes.agentPolicies.name:
        this.selectedKey = AgentTabs.Policies;
        this.SelectedViewModel = AgentTabViewModelTypes.Policies;
        this.SelectedBaseViewModel = this.AgentPoliciesViewModel;
        if (this.agentId) this.AgentPoliciesViewModel.load(+this.agentId);
        break;
      case routes.agentStateLicenses.name:
        this.selectedKey = AgentTabs.StateLicenses;
        this.SelectedViewModel = AgentTabViewModelTypes.StateLicenses;
        this.SelectedBaseViewModel = this.StateLicensesListViewModel;
        if (this.agentId) this.StateLicensesListViewModel.Load(+this.agentId);
        break;
      case routes.editAgentStateLicense.name:
        this.selectedKey = AgentTabs.StateLicenses;
        this.SelectedViewModel = AgentTabViewModelTypes.EditStateLicense;
        this.SelectedBaseViewModel = this.StateLicensesEditViewModel;
        this.StateLicensesEditViewModel.Load(+routeParamObj.Id);
        localStorage.setItem("agentIdForStateTab", this.agentId || "");
        break;
      case routes.addAgentStateLicense.name:
        this.selectedKey = AgentTabs.StateLicenses;
        this.SelectedViewModel = AgentTabViewModelTypes.AddStateLicense;
        this.SelectedBaseViewModel = this.StateLicensesAddViewModel;
        if (this.agentId) this.StateLicensesAddViewModel.Load(undefined);
        localStorage.setItem("agentIdForStateTab", this.agentId || "");
        break;
      case routes.agentAppointments.name:
        this.selectedKey = AgentTabs.Appointments;
        this.SelectedViewModel = AgentTabViewModelTypes.Appointments;
        this.SelectedBaseViewModel = this.AgentAppointmentsViewModel;
        if (this.agentId) this.AgentAppointmentsViewModel.load(+this.agentId);
        break;
      case routes.manageRTS.name:
        this.selectedKey = AgentTabs.ManageRTS;
        this.SelectedViewModel = AgentTabViewModelTypes.ManageRTS;
        this.SelectedBaseViewModel = this.ManageRTSViewModel;
        if (this.agentId) this.ManageRTSViewModel.Load(+this.agentId, this.npn);
        break;
      case routes.agentContracts.name:
        this.selectedKey = AgentTabs.Contracts;
        this.SelectedViewModel = AgentTabViewModelTypes.Contracts;
        this.SelectedBaseViewModel = this.AgentLicensesViewModel;
        this.ContractDetailsViewModel.carrierName = "";
        if (this.agentId)
          this.AgentLicensesViewModel.Load(+this.agentId, undefined, undefined);
        break;
      case routes.agentContractsMarketer.name:
        this.selectedKey = AgentTabs.Contracts;
        this.SelectedViewModel = AgentTabViewModelTypes.Contracts;
        this.SelectedBaseViewModel = this.AgentLicensesViewModel;
        if (this.agentId)
          this.AgentLicensesViewModel.Load(
            +this.agentId,
            routeParamObj.marketerId,
            routeParamObj.divisionId
          );

        break;

      case routes.contractDetails.name:
        this.selectedKey = AgentTabs.Contracts;
        this.SelectedViewModel = AgentTabViewModelTypes.ContractDetails;
        this.SelectedBaseViewModel = this.ContractDetailsViewModel;
        if (this.agentId)
          this.ContractDetailsViewModel.Load(this.contractId, +this.agentId);
        this.ContractDetailsViewModel.Route(currentRoute);
        break;
      case routes.agentTasks.name:
        this.selectedKey = AgentTabs.Tasks;
        this.SelectedViewModel = AgentTabViewModelTypes.Tasks;
        this.SelectedBaseViewModel = this.AgentTasksViewModel;
        if (this.agentId) this.AgentTasksViewModel.load(+this.agentId);
        break;
      case routes.addTask.name:
        this.selectedKey = AgentTabs.Tasks;
        this.SelectedViewModel = AgentTabViewModelTypes.AddTask;
        this.SelectedBaseViewModel = this.AddTaskViewModel;
        if (this.agentId) this.AddTaskViewModel.Load(+this.agentId, undefined);
        break;
      case routes.editTask.name:
        this.selectedKey = AgentTabs.Tasks;
        this.SelectedViewModel = AgentTabViewModelTypes.EditTask;
        this.SelectedBaseViewModel = this.AddTaskViewModel;
        if (this.agentId)
          this.AddTaskViewModel.Load(+this.agentId, this.taskId);
        break;
      case routes.agentDocs.name:
        this.selectedTabIndex = 10;
        this.selectedKey = AgentTabs.Documents;
        this.SelectedViewModel = AgentTabViewModelTypes.Documents;
        this.SelectedBaseViewModel = this.AgentDocsViewModel;
        let docIdTemp =
          (localStorage.getItem("SubmitFromWorkFlowDocId") !== null &&
            localStorage.getItem("SubmitFromWorkFlowDocId")) ||
          "0";
        if (this.agentId)
          this.AgentDocsViewModel.Load(+this.agentId, parseInt(docIdTemp));
        break;
      case routes.agentTripPoints.name:
        this.selectedKey = AgentTabs.TripPoints;
        this.SelectedViewModel = AgentTabViewModelTypes.TripPoints;
        this.SelectedBaseViewModel = this.TripPointsViewModel;
        if (this.agentId) this.TripPointsViewModel.Load(+this.agentId);
        break;
      case routes.agentMessaging.name:
        this.selectedKey = AgentTabs.Messaging;
        this.SelectedViewModel = AgentTabViewModelTypes.Messaging;
        this.SelectedBaseViewModel = this.AgentEmailViewModel;
        RouteParamDetails.tabId = undefined;
        this.AgentEmailViewModel.setAppInlineProfileViewModel(
          this.AppInlineProfileViewModel
        );
        if (this.agentId) {
          this.AgentEmailViewModel.setSMS("");
          this.AgentEmailViewModel.Load(
            +this.agentId,
            this.nameForSMSTitle || ""
          );
        }
        break;
      case routes.agentMessagingByTab.name:
        this.selectedKey = AgentTabs.Messaging;
        this.SelectedViewModel = AgentTabViewModelTypes.Messaging;
        this.SelectedBaseViewModel = this.AgentEmailViewModel;
        RouteParamDetails.tabId = routeParamObj.tabId;
        this.AgentEmailViewModel.setAppInlineProfileViewModel(
          this.AppInlineProfileViewModel
        );
        if (this.agentId) {
          this.AgentEmailViewModel.setSMS("");
          this.AgentEmailViewModel.Load(
            +this.agentId,
            this.nameForSMSTitle || ""
          );
        }
        break;
      case routes.agentProduction.name:
        this.selectedKey = AgentTabs.Production;
        this.SelectedViewModel = AgentTabViewModelTypes.Production;
        this.SelectedBaseViewModel = this.ProductionViewModel;
        if (this.agentId)
          this.ProductionViewModel.Load(
            +this.agentId,
            this.AgentSummaryViewModel.agentSummary != null
              ? this.AgentSummaryViewModel.agentSummary.name
              : "",
            this.AgentSummaryViewModel.agentSummary != null
              ? this.AgentSummaryViewModel.agentSummary.agentAWNs
              : undefined
          );
        break;
      case routes.agentOrders.name:
        this.selectedKey = AgentTabs.Orders;
        this.SelectedViewModel = AgentTabViewModelTypes.Orders;
        this.SelectedBaseViewModel = this.AgentOrdersViewModel;
        if (this.agentId) this.AgentOrdersViewModel.Load(+this.agentId, true);
        break;
      case routes.orderDetails.name:
        this.selectedKey = AgentTabs.Orders;
        this.SelectedViewModel = AgentTabViewModelTypes.OrderDetails;
        this.SelectedBaseViewModel = this.OrderDetailsViewModel;
        if (routeParamObj.orderId)
          this.OrderDetailsViewModel.Load(routeParamObj.orderId, true);
        break;
      case routes.addSupplyOrder.name:
        this.selectedKey = AgentTabs.Orders;
        this.SelectedViewModel = AgentTabViewModelTypes.AddSupplyOrder;
        this.AddSupplyOrderViewModel.setAddressData(
          this.AgentSummaryViewModel.getAddressList()
        );
        this.SelectedBaseViewModel = this.AddSupplyOrderViewModel;
        if (this.agentId)
          this.AddSupplyOrderViewModel.Load(+this.agentId, undefined, true);
        break;
      case routes.editSupplyOrder.name:
        this.selectedKey = AgentTabs.Orders;
        this.SelectedViewModel = AgentTabViewModelTypes.AddSupplyOrder;
        this.AddSupplyOrderViewModel.setAddressData(
          this.AgentSummaryViewModel.getAddressList()
        );
        this.SelectedBaseViewModel = this.AddSupplyOrderViewModel;
        if (this.agentId)
          this.AddSupplyOrderViewModel.Load(
            +this.agentId,
            parseInt(routeParamObj.orderId),
            true
          );
        break;
      case routes.addSupplyItem.name:
        this.selectedKey = AgentTabs.Orders;
        this.SelectedViewModel = AgentTabViewModelTypes.AddSupplyItem;
        this.SelectedBaseViewModel = this.AddSupplyItemViewModel;
        this.AddSupplyItemViewModel.Load();
        this.AddSupplyItemViewModel.Route(currentRoute);
        break;
      case routes.agentWorkflow.name:
        this.selectedKey = AgentTabs.Workflow;
        this.SelectedViewModel = AgentTabViewModelTypes.Workflow;
        this.SelectedBaseViewModel = this.WorkflowViewModel;
        if (this.agentId) this.WorkflowViewModel.Load(+this.agentId);
        break;
      case routes.agentAccount.name:
        this.selectedKey = AgentTabs.Account;
        this.SelectedViewModel = AgentTabViewModelTypes.Account;
        this.SelectedBaseViewModel = this.AgentAccountViewModel;
        if (this.agentId)
          this.AgentAccountViewModel.Load(+this.agentId, this.agentName);
        break;
      case routes.agentProfile.name:
        this.selectedKey = AgentTabs.Profile;
        this.SelectedViewModel = AgentTabViewModelTypes.Profile;
        this.SelectedBaseViewModel = this.AgentProfileViewModel;
        if (this.agentId) this.AgentProfileViewModel.Load(+this.agentId);
        break;
      // case routes.addAgentDebt.name:
      //   this.selectedKey = AgentTabs.Account;
      //   this.SelectedViewModel = AgentTabViewModelTypes.AddAgentDebt;
      //   this.SelectedBaseViewModel = this.AddAgentDebtViewModel;
      //   if (this.agentId)
      //     this.AddAgentDebtViewModel.Load(+this.agentId, undefined);
      //   break;
      //case routes.editAgentDebt.name:
      //  this.selectedKey = AgentTabs.Account;
      //  this.SelectedViewModel = AgentTabViewModelTypes.EditAgentDebt;
      //  this.SelectedBaseViewModel = this.AddAgentDebtViewModel;
      //  if (this.agentId)
      //    this.AddAgentDebtViewModel.Load(+this.agentId, this.debtId);
      //  break;
      case routes.addProductDetails.name:
        this.selectedKey = AgentTabs.Contracts;
        this.SelectedViewModel = AgentTabViewModelTypes.AddEditProductDetails;
        this.SelectedBaseViewModel = this.AddEditLevelViewModel;
        this.AddEditLevelViewModel.setLevelId(0);
        this.AddEditLevelViewModel.Load(true);
        break;
      case routes.editProductDetails.name:
        this.selectedKey = AgentTabs.Contracts;
        this.SelectedViewModel = AgentTabViewModelTypes.AddEditProductDetails;
        this.SelectedBaseViewModel = this.AddEditLevelViewModel;
        this.AddEditLevelViewModel.setLevelId(routeParamObj.levelId);
        this.AddEditLevelViewModel.Load(true);
        break;
      case routes.addContractLevel.name:
      case routes.editContractLevel.name:
      case routes.contractLevels.name:
      case routes.addPaymentPlan.name:
      case routes.editPaymentPlan.name:
      case routes.contractPaymentPlans.name:
      case routes.addPaymentPlanFromLevel.name:
        this.loadContractDetails(currentRoute);
        break;
      case routes.editContractPaymentPlans.name:
      case routes.editContractAddPaymentPlan.name:
      case routes.editContractEditPaymentPlan.name:
      case routes.editContractAddPaymentPlanFromLevel.name:
        this.loadEditContractDetails(currentRoute);
        break;
      case routes.agentAutoAppointments.name:
        this.selectedKey = AgentTabs.Appointments;
        this.SelectedViewModel = AgentTabViewModelTypes.AutoAppointments;
        //this.SelectedBaseViewModel = this.AgentAutoAppointmentsViewModel;
        this.AgentAutoAppointmentsViewModel.load(this.agentId);
        break;
    }
  }
  @action loadContractDetails(currentRoute: RouteList) {
    this.selectedKey = AgentTabs.Contracts;
    this.SelectedViewModel = AgentTabViewModelTypes.ContractDetails;
    this.SelectedBaseViewModel = this.ContractDetailsViewModel;
    if (this.agentId) {
      this.ContractDetailsViewModel.Load(this.contractId, +this.agentId);
      this.ContractDetailsViewModel.Route(currentRoute);
    }
  }
  @action loadEditContractDetails(currentRoute: RouteList) {
    this.selectedKey = AgentTabs.Contracts;
    this.SelectedViewModel = AgentTabViewModelTypes.AddContract;
    this.SelectedBaseViewModel = this.AddEditContractViewModel;
    if (this.agentId) {
      if (!this.AddEditContractViewModel.isContractLoaded) {
        this.AddEditContractViewModel.Load(this.agentId, +this.contractId);
      }
      this.AddEditContractViewModel.Route(currentRoute);
    }
  }
  @observable agentTabItems = [
    { label: "Summary", value: 0 },
    { label: "Notes", value: 1 },
    { label: "Messaging", value: 2 },
    { label: "Policies", value: 3 },
    { label: "Contracts", value: 4 },
    { label: "Production", value: 5 },
    { label: `Manage RTS`, value: 6 },
    { label: `Orders (${this.agentOrdersCount})`, value: 7 },
    { label: "Tasks", value: 8 },
    { label: "Documents", value: 9 },
    { label: "Trip Points", value: 10 },
    { label: "Account", value: 11 },
    { label: `Workflow (${this.agentWorkflowCount})`, value: 12 },
  ];
  @computed get getTabIndex(): number {
    let tabIndex = 0;
    switch (this.selectedKey) {
      case AgentTabs.Summary:
        tabIndex = 0;
        break;
      case AgentTabs.Notes:
        tabIndex = 1;
        break;
      case AgentTabs.Messaging:
        tabIndex = 2;
        break;
      case AgentTabs.Policies:
        tabIndex = 3;
        break;
      case AgentTabs.StateLicenses:
        tabIndex = 4;
        break;
      case AgentTabs.Contracts:
        tabIndex = 5;
        break;
      case AgentTabs.Production:
        tabIndex = 6;
        break;
      case AgentTabs.ManageRTS:
        tabIndex = 7;
        break;
      case AgentTabs.Orders:
        tabIndex = 8;
        break;
      case AgentTabs.Tasks:
        tabIndex = 9;
        break;
      case AgentTabs.Documents:
        tabIndex = 10;
        break;
      case AgentTabs.TripPoints:
        tabIndex = 11;
        break;
      case AgentTabs.Account:
        tabIndex = 12;
        break;
      case AgentTabs.Workflow:
        tabIndex = 13;
        break;
      default:
        tabIndex = 0;
        break;
    }
    return tabIndex;
  }

  @action actionLog = () => {
    var dto: ActionLogModel = {
      actionId: 1,
      entityId: this.agentId ? +this.agentId : undefined,
      userId: UserContext.getUserId(),
    };
    IdentityStore.createActionLog(dto);
  };

  @action handleTabClick = (index: number | undefined, key: string): void => {
    this.selectedTabIndex = index;
    PageContext.setIsLoadingVisible(false);
    switch (key) {
      case "Summary":
        this.getAlertByAgentId(this.agentId);
        this.loadAgentSummary();
        this.handleLinkClick(AgentTabs.Summary);
        break;
      case "Notes":
        this.handleLinkClick(AgentTabs.Notes);
        break;
      case "Messaging":
        this.handleLinkClick(AgentTabs.Messaging);
        break;
      case "Policies":
        this.handleLinkClick(AgentTabs.Policies);
        break;
      case "State Licenses":
        this.handleLinkClick(AgentTabs.StateLicenses);
        break;
      case "Contracts":
        this.handleLinkClick(AgentTabs.Contracts);
        break;
      case "Production":
        this.handleLinkClick(AgentTabs.Production);
        break;
      case `Manage RTS`:
        this.handleLinkClick(AgentTabs.ManageRTS);
        break;
      case `Orders (${this.agentOrdersCount})`:
        this.handleLinkClick(AgentTabs.Orders);
        break;
      case "Tasks":
        this.handleLinkClick(AgentTabs.Tasks);
        break;
      case "Documents":
        if (Utils.hasUserPermission(Permission.ViewDocsTabOnAgentScreen)) {
          this.handleLinkClick(AgentTabs.Documents);
        }
        break;
      case "Points":
        this.handleLinkClick(AgentTabs.TripPoints);
        break;
      case "Account":
        this.handleLinkClick(AgentTabs.Account);
        break;
      case `Workflow (${this.agentWorkflowCount})`:
        this.handleLinkClick(AgentTabs.Workflow);
        break;
    }
  };

  @action handleLinkClick = (item: string | undefined): void => {
    if (item) {
      this.selectedKey = item;
      switch (item) {
        case AgentTabs.Summary:
          RouteParamDetails.notesUserId = 0;
          routes.agentSummary.push({
            agentId: "" + this.agentId,
          });
          return;
        case AgentTabs.Notes:
          routes.agentNotes.push({
            agentId: "" + this.agentId,
          });
          return;
        case AgentTabs.StateLicenses:
          routes.agentStateLicenses.push({
            agentId: "" + this.agentId,
          });
          return;
        case AgentTabs.Contracts:
          routes.agentContracts.push({
            agentId: "" + this.agentId,
          });
          return;
        case AgentTabs.Production:
          routes.agentProduction.push({
            agentId: "" + this.agentId,
          });
          return;
        case AgentTabs.Policies:
          routes.agentPolicies.push({
            agentId: "" + this.agentId,
          });
          return;
        case AgentTabs.Messaging:
          routes.agentMessaging.push({
            agentId: "" + this.agentId,
          });
          return;
        case AgentTabs.PaymentPlans:
          return;

        case AgentTabs.Appointments:
          routes.agentAppointments.push({ agentId: "" + this.agentId });
          return;
        case AgentTabs.ManageRTS:
          routes.manageRTS.push({ agentId: "" + this.agentId });
          return;
        case AgentTabs.Orders:
          routes.agentOrders.push({
            agentId: "" + this.agentId,
          });
          return;
        case AgentTabs.Tasks:
          routes.agentTasks.push({
            agentId: "" + this.agentId,
          });
          return;
        case AgentTabs.Documents:
          routes.agentDocs.push({
            agentId: "" + this.agentId,
          });
          return;
        case AgentTabs.TripPoints:
          routes.agentTripPoints.push({
            agentId: "" + this.agentId,
          });
          return;
        case AgentTabs.Profile:
          routes.agentProfile.push({
            agentId: "" + this.agentId,
          });
          return;
        case AgentTabs.Account:
          routes.agentAccount.push({
            agentId: "" + this.agentId,
          });
          return;
        case AgentTabs.Workflow:
          routes.agentWorkflow.push({
            agentId: "" + this.agentId,
          });
          return;
        default:
          return;
      }
    }
  };
}
