import UserContext from "./UserContext";
import AccountTabContext from "./AccountTabContext";
import ContractTabContext from "./ContractTabContext";
import LeadsTabContext from "./LeadsTabContext";
import { AppHostKey, AppInsightsKey } from "./enum/ThemeSettings";
import DocWorkflowContext from "./DocWorkflowContext";
import {
  Auth0ConfigurationManager,
  ConfigurationManager,
} from "./ConfigurationManager";
import { getToken } from "./Auth0Authenticator";
import { Permission } from "./enum/Permission";
import Collection from "./CollectionHelper";
import { Constants, homeKpis } from "./enum/Constants";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import { getDangerouslyHtml } from "../views/components/controls/IMASTemplates";
import { toJS } from "mobx";
class Utils {
  public formatDate(param: any) {
    if (param) {
      let date = new Date(`${param}`);
      let d = date.getUTCDate();
      let m = date.getUTCMonth();
      let y = date.getUTCFullYear();
      return `${m + 1}/${d}/${y}`;
    }
    return "";
  }
  public carriersFilter(options: any) {
    if (options) {
      return (options || []).filter(
        (val: any) => !val.label.includes("(Inactive)")
      );
    }
  }

  public formatDateForCalendar(param: Date | undefined) {
    if (param) {
      let date = new Date(`${param}`);
      let d = date.getUTCDate();
      let m = date.getUTCMonth();
      let y = date.getUTCFullYear();
      return new Date(`${m + 1}/${d}/${y}`);
    }
    return new Date();
  }
  public async saveDownloadedReportFile(result: any, name: string) {
    if (result) {
      await fetch(`data:${result.contentType};base64,${result.data}`)
        .then((response) => response.blob())
        .then(function (myBlob) {
          var link = document.createElement("a");
          var url = window.URL.createObjectURL(myBlob);
          let n = result.fileName ? result.fileName.split(".")[0] : "";
          link.href = url;
          link.download = n !== "" ? n : name;
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        });
    }
  }
  public b64toBlob(b64Data: any, contentType: any, sliceSize: any) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  public isDateValid(date: Date | undefined) {
    if (date === undefined) return false;
    // if the date is invalid, getTime will return NaN.
    // One instance of NaN will not be equal to another NaN
    var tempDate = date.getTime();
    return tempDate === date.getTime();
  }
  public getValidDate(date: Date | undefined) {
    return this.isDateValid(date) ? date : undefined;
  }
  public getDateinyymmdd(date: Date | undefined) {
    if (date) {
      let d = new Date(date);
      let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
      let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
      let result = ye + "-" + mo + "-" + da;
      return result === "01/01/1" ? "" : result;
    }
    return "";
  }
  public getDateInFormat(date: Date | undefined) {
    if (date) {
      let result = new Intl.DateTimeFormat("en-US", {
        //en-US
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(new Date(date));
      return result === "01/01/1" ? "" : result;
    }
    return "";
  }
  public getDateInFormatShortYear(date: Date | undefined) {
    if (date) {
      let result = new Intl.DateTimeFormat("en-US", {
        //en-US
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(new Date(date));
      return result === "01/01/1" ? "" : result;
    }
    return "";
  }
  public getDateFormat(date: Date | undefined) {
    if (date) {
      let d = new Date(date);
      let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
      let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
      let result = mo + "-" + da + "-" + ye;
      return result === "01/01/1" ? "" : result;
    }
    return "";
  }

  public DateFormatWithSlash(date: Date | undefined) {
    if (date) {
      let d = new Date(date);
      let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
      let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
      let minutes = new Intl.DateTimeFormat("en", {
        hour: "2-digit",
        minute: "numeric",
      }).format(d);
      let result = mo + "/" + da + "/" + ye + " " + minutes;
      return result === "01/01/1" ? "" : result;
    }
    return "";
  }

  public getDateFormatForYyMmDd(date: Date | undefined) {
    if (date) {
      let d = new Date(date);
      let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
      let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
      let result = ye + "-" + mo + "-" + da;
      return result === "01/01/1" ? "" : result;
    }
    return "";
  }
  public getCMNotesDateFormat(date: Date | undefined) {
    if (date) {
      return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        day: "2-digit",
        month: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(date);
    }
    return "";
  }
  public getSmsDateFormat(date: Date | undefined) {
    if (date) {
      const options = { month: "long", day: "numeric", year: "numeric" };
      const d = new Date(date);
      let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      let mo = new Intl.DateTimeFormat("en", { month: "long" }).format(d);
      let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
      let wd = new Intl.DateTimeFormat("en", { weekday: "long" }).format(d);
      let result = wd + ", " + mo + " " + da + ", " + ye;
      return result === "01/01/1" ? "" : result;
    }
    return "";
  }
  public getDateTimeInFormat(date: Date | undefined) {
    if (date) {
      return new Intl.DateTimeFormat("en-US", {
        //en-US
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      }).format(new Date(date));
    }
    return "";
  }
  public getDateTimeSecondInFormat(date: Date | undefined) {
    if (date) {
      return new Intl.DateTimeFormat("en-US", {
        //en-US
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "numeric",
        second: "numeric",
      })
        .format(new Date(date))
        .replace(",", "");
    }
    return "";
  }
  public getTimeInFormatSSR(date: Date | undefined) {
    if (date) {
      return new Intl.DateTimeFormat("en-US", {
        //en-US
        hour: "2-digit",
        minute: "numeric",
      }).format(new Date(date));
    }
    return "";
  }
  public getTimeInFormat(date: Date | undefined) {
    if (date) {
      return new Intl.DateTimeFormat("en-US", {
        //en-US
        hour: "numeric",
        minute: "numeric",
      }).format(new Date(date));
    }
    return "";
  }
  compareCarrierName = (a: any, b: any) => {
    // Use toUpperCase() to ignore character casing
    const nameA = a.carrierName.toUpperCase();
    const nameB = b.carrierName.toUpperCase();
    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  };
  getDateAndTime = (dateParam: any, timeParam: any) => {
    let date = dateParam ? new Date(dateParam) : new Date();
    let tempTime = timeParam ? timeParam.split(" ")[0].split(":") : ["0", "0"];
    let tempFormat = timeParam ? timeParam.split(" ")[1] : "AM";
    return date.setHours(
      tempFormat === "PM"
        ? Number(tempTime[0]) === 12
          ? Number(tempTime[0])
          : Number(tempTime[0]) + 12
        : Number(tempTime[0]),
      Number(tempTime[1])
    );
  };
  public onShow = (param: string) => {
    let okButton = document.getElementById(param);
    okButton && okButton.focus();
  };
  getSubstringFromString = (
    str: any,
    startIndex: number,
    lastIndex: number
  ) => {
    if (str != null && str.length > 0 && str.length > lastIndex + 1) {
      return str.substring(startIndex, lastIndex) + "...";
    } else {
      return str;
    }
  };
  compareListItem = (a: any, b: any) => {
    // Use toUpperCase() to ignore character casing
    const nameA = !a === null ? a.text.toUpperCase() : "";
    const nameB = !b === null ? b.text.toUpperCase() : "";
    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  };
  compareCarrier = (a: any, b: any) => {
    // Use toUpperCase() to ignore character casing
    const nameA = !a === null ? a.text.toUpperCase() : "";
    const nameB = !b === null ? b.text.toUpperCase() : "";
    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  };
  getCurrencyFormat(value: number | undefined) {
    if (value) {
      let result = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value);
      return result.trim().length > 0 ? result : "$0.00";
    }
    return "$0.00";
  }
  getFlatRate(value: number | undefined) {
    var flatRateName = "" + value;
    if (value && value === 999) {
      flatRateName = "FLAT";
    }
    return flatRateName + "%";
  }
  getCurrencyWithDecimalFormat(value: number | undefined) {
    if (value) {
      let result = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        currencyDisplay: "symbol",
      }).format(value);
      return result.trim().length > 0 ? result : "$0.00";
    }
    return "$0.00";
  }
  getDecimalFormat(value: number | undefined, fractionDigits: number = 2) {
    if (value) {
      let result = new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      }).format(value);
      return result.trim().length > 0 ? result : "0.00";
    }
    return "0.00";
  }
  getObject(value: any) {
    return JSON.parse(JSON.stringify(value));
  }
  goBack() {
    return window.history.back();
  }
  goBackWithNoReload() {
    //window.location    = document.referrer;`${document.referrer.toString()}?reload=false`;
  }
  goBackTo(value: number) {
    return window.history.go(value);
  }
  getAgentSearchType(a: string) {
    try {
      var number = +a;
      if (number) {
        return 2;
      } else {
        return 1;
      }
    } catch (e) {
      return 1;
    }
  }
  getAgentSearchTypeForRTSReview(a: string) {
    try {
      var number = +a;
      if (number) {
        return 6;
      } else {
        return 1;
      }
    } catch (e) {
      return 1;
    }
  }
  getAgentLocationSearchType(a: string) {
    try {
      var number = +a;
      if (number) {
        return 15;
      } else {
        return 14;
      }
    } catch (e) {
      return 14;
    }
  }
  getInsuredSearchType(a: string) {
    try {
      var number = +a;
      if (number) {
        return 6;
      } else {
        return 1;
      }
    } catch (e) {
      return 1;
    }
  }
  getAgencySearchType(a: string) {
    try {
      var number = +a;
      if (number) {
        return 13;
      } else {
        return 12;
      }
    } catch (e) {
      return 12;
    }
  }
  public mapListItemsToDropdown(
    listItems: any[],
    collection: Collection<{ label: string; value: string }>,
    defaultName: string = "All",
    defaultValue: string = "0"
  ) {
    var assign = [];
    if (defaultName !== "") {
      var defaultItem = { label: defaultName, value: defaultValue };
      assign.push(defaultItem);
    }
    if (listItems != null) {
      listItems.forEach((item) => {
        var data = {
          label: item.text ? item.text : "",
          value: item.value ? item.value : "",
        };
        assign.push(data);
      });
    }
    collection.values = assign;
  }
  public mapListItemsToRadioOptions(
    listItems: any[],
    collection: Collection<{ label: string; value: string }>,
    defaultName: string = "All",
    defaultValue: string = "0"
  ) {
    var assign = [];
    if (defaultName !== "") {
      var defaultItem = { label: defaultName, value: defaultValue };
      assign.push(defaultItem);
    }
    if (listItems != null) {
      listItems.forEach((item) => {
        var data = {
          label: item ? item : "",
          value: item ? item : "",
        };
        assign.push(data);
      });
    }
    collection.values = assign;
  }
  public mapListItemsAsList(
    listItems: any[],
    defaultName: string = "All",
    defaultValue: string = "0"
  ) {
    var assign = [];
    if (defaultName !== "") {
      var defaultItem = { label: defaultName, value: defaultValue };
      assign.push(defaultItem);
    }
    if (listItems != null) {
      listItems.forEach((item) => {
        var data = {
          label: item.text ? item.text : "",
          value: item.value ? item.value : "",
        };
        assign.push(data);
      });
    }
    return assign;
  }
  validateDOB(dob: Date) {
    var error = false;
    var parts = this.getDateInFormat(dob).split("/");
    var dtDOB = new Date(parts[0] + "/" + parts[1] + "/" + parts[2]);
    var dtCurrent = new Date();
    if (dtCurrent.getFullYear() - dtDOB.getFullYear() < 18) {
      error = true;
    }
    if (dtCurrent.getFullYear() - dtDOB.getFullYear() === 18) {
      if (dtCurrent.getMonth() < dtDOB.getMonth()) {
        error = true;
      }
      if (dtCurrent.getMonth() === dtDOB.getMonth()) {
        if (dtCurrent.getDate() < dtDOB.getDate()) {
          error = true;
        }
      }
    }
    return error;
  }
  errorMsg = () => {
    let response = {
      status: 400,
      title: Constants.genericView,
      errors: { "": [] },
    };
    return JSON.stringify(response);
  };
  hasUserPermission(permission: Permission, id?: number) {
    return (
      UserContext.permissions && UserContext.hasPermmission(permission, id)
    );
  }
  isDownlineAgency() {
    return UserContext.userHasDownlineAgency();
  }
  getUserProperties() {
    return UserContext.getUserProperties();
  }
  getDeleteDocument() {
    return UserContext.hasDeleteDoc();
  }
  getUserId() {
    return UserContext.getUserId();
  }
  setAccountSubTab(value: number) {
    return AccountTabContext.setSelectedTab(value);
  }
  getAccountSubTab() {
    return AccountTabContext.getSelectedTab();
  }

  setContractSubTab(value: number) {
    return ContractTabContext.setSelectedTab(value);
  }
  getContractSubTab() {
    return ContractTabContext.getSelectedTab();
  }
  setLeadsTab(value: number) {
    return LeadsTabContext.setSelectedTab(value);
  }
  getLeadsTab() {
    return LeadsTabContext.getSelectedTab();
  }
  getDocInfo() {
    return DocWorkflowContext.getIsDocInfo();
  }
  setDocInfo(value: boolean, state: any) {
    return DocWorkflowContext.settIsDocInfo(value, state);
  }
  getHost() {
    let location = window.location.host.toLowerCase();
    return location;
  }
  getTimezoneOffset = () => {
    return new Date().getTimezoneOffset();
  };
  getApiKey = async (): Promise<string> => {
    var token = await getToken();
    if (!token) {
      token = await getToken();
    }
    return token ? `Bearer ${token}` : "";
  };
  getServiceUrlByEnvironment() {
    let location = window.location.origin.toLowerCase();
    if (location.includes("localhost") || location.includes("feature")) {
      return ConfigurationManager.REACT_APP_LOCAL_SERVICE_URL;
    }
    return location;
  }

  getMicroServiceUrl(service: string) {
    let location = window.location.origin.toLowerCase();
    if (location.includes("localhost") || location.includes("feature")) {
      return ConfigurationManager.microServices[service];
    }
    return location;
  }

  getLoginUserImpersonatingPermission() {
    return this.hasUserPermission(Permission.ImpersonateAllActiveUsers);
  }

  getAppInsightsKey() {
    let location = window.location.host.toLowerCase();
    var key = "";
    if (location.includes("localhost") || location.includes("-dev.")) {
      key = AppInsightsKey.Dev;
    } else if (location.includes("-qa.")) {
      key = AppInsightsKey.Qa;
    } else if (location.includes("-uat.")) {
      key = AppInsightsKey.Uat;
    } else if (location.includes("-stage.")) {
      key = AppInsightsKey.Stage;
    } else if (location.includes("-feature")) {
      key = AppInsightsKey.Feature;
    } else {
      key = AppInsightsKey.Prod;
    }
    return key;
  }
  getAppHostKey() {
    let location = window.location.host.toLowerCase();
    var key = "";
    if (location.includes("localhost") || location.includes("dev")) {
      key = AppHostKey.Dev;
    } else if (location.includes("qa")) {
      key = AppHostKey.Qa;
    } else if (location.includes("uat")) {
      key = AppHostKey.Uat;
    } else if (location.includes("stage")) {
      key = AppHostKey.Stage;
    } else if (location.includes("feature")) {
      if (location.includes("00002")) {
        key = AppHostKey.Feature2;
      } else if (location.includes("00006") || location.includes("00017")) {
        key = AppHostKey.Feature6;
      } else if (location.includes("00014")) {
        key = AppHostKey.Feature14;
      } else {
        key = AppHostKey.Feature;
      }
    } else {
      key = AppHostKey.Prod;
    }
    return key;
  }
  getBUName() {
    let location = this.getServiceUrlByEnvironment();
    let startIndex = location.indexOf("-");
    let lastIndex = location.indexOf(".");
    let buName = location.substring(startIndex + 1, lastIndex);
    if (
      buName.includes("-dev") ||
      buName.includes("-qa") ||
      buName.includes("-uat") ||
      buName.includes("-stage")
    ) {
      buName = buName
        .replace("-dev", "")
        .replace("-qa", "")
        .replace("-uat", "")
        .replace("-stage", "");
    }
    if (buName.includes("feature")) {
      return ConfigurationManager.REACT_FEATURE_BU;
    }
    if (buName.includes("localhost")) {
      return ConfigurationManager.REACT_FEATURE_BU;
    }
    return buName.toUpperCase();
  }

  getBUNameForPolicy() {
    let location = this.getServiceUrlByEnvironment();
    let startIndex = location.indexOf("-");
    let lastIndex = location.indexOf(".");
    let buName = location.substring(startIndex + 1, lastIndex);
    if (
      buName.includes("-dev") ||
      buName.includes("-qa") ||
      buName.includes("-uat") ||
      buName.includes("-stage")
    ) {
      buName = buName
        .replace("-dev", "")
        .replace("-qa", "")
        .replace("-uat", "")
        .replace("-stage", "");
    }
    if (buName.includes("feature")) {
      return ConfigurationManager.REACT_FEATURE_BU;
    }
    if (buName.includes("localhost")) {
      return ConfigurationManager.REACT_FEATURE_BU;
    }
    if (buName == "demo") {
      buName = "PRE";
    }
    return buName.toUpperCase();
  }
  getLogo() {
    return "connect-logo-new.svg";
  }
  getLogoCss() {
    return "iconnect";
  }
  getClientIdByEnvironment() {
    let location = window.location.host.toLowerCase();
    var clientId = ConfigurationManager.REACT_APP_PROD_CLIENT_ID;
    if (
      location.includes("localhost") ||
      location.includes("-dev") ||
      location.includes("-feature")
    ) {
      clientId = ConfigurationManager.REACT_APP_CLIENT_ID;
    } else if (location.includes("-qa")) {
      clientId = ConfigurationManager.REACT_APP_QA_CLIENT_ID;
    } else if (location.includes("-stage")) {
      clientId = ConfigurationManager.REACT_APP_STAGE_CLIENT_ID;
    } else if (location.includes("-uat")) {
      clientId = ConfigurationManager.REACT_APP_UAT_CLIENT_ID;
    }
    return clientId;
  }

  //Get Auth0 configuration id based on the environment
  getAuth0ClientIdByEnvironment = (auth0Key: any): string => {
    let location = window.location.host.toLowerCase();
    let aut0keys: any;
    let configEnvironment: any;

    if (
      location.includes("localhost") ||
      location.includes("-dev") ||
      location.includes("-feature")
    ) {
      configEnvironment = Auth0ConfigurationManager.auth0ApiKeys.filter((x) =>
        x.env.includes("dev")
      )[0];
      aut0keys = configEnvironment[auth0Key];
    } else if (location.includes("-qa")) {
      configEnvironment = Auth0ConfigurationManager.auth0ApiKeys.filter((x) =>
        x.env.includes("qa")
      )[0];
      aut0keys = configEnvironment[auth0Key];
    } else if (location.includes("-uat")) {
      configEnvironment = Auth0ConfigurationManager.auth0ApiKeys.filter((x) =>
        x.env.includes("uat")
      )[0];
      aut0keys = configEnvironment[auth0Key];
    } else if (location.includes("-stage")) {
      configEnvironment = Auth0ConfigurationManager.auth0ApiKeys.filter((x) =>
        x.env.includes("stage")
      )[0];
      aut0keys = configEnvironment[auth0Key];
    } else {
      configEnvironment = Auth0ConfigurationManager.auth0ApiKeys.filter((x) =>
        x.env.includes("prod")
      )[0];
      aut0keys = configEnvironment[auth0Key];
    }
    return aut0keys;
  };

  isMobileView = () => {
    return window.screen.width < 767;
  };

  getValueWithNegativeAndPrefix = (value: string, prefix: string) => {
    value =
      value &&
      value.length > 1 &&
      value != "$0" &&
      value.charAt(value.length - 1) == "-"
        ? value.slice(0, -1)
        : value;

    var temp = value.replaceAll(prefix, "").replaceAll(",", "")
      ? value.replaceAll(prefix, "").replaceAll(",", "")
      : "";
    var temp1 = temp;

    if (temp !== "") {
      if (temp.includes(".")) {
        var arr = temp.split(".");
        var beforeDecimal = arr[0];
        var afterDecimal = arr[1];
        if (beforeDecimal !== "")
          temp1 =
            parseFloat(beforeDecimal).toLocaleString("en-us").toString() +
            "." +
            afterDecimal;
      }
    }

    // if(temp.lastIndexOf("0")   == temp.indexOf(".")+1)
    // {
    //   if(  temp.length ==temp.indexOf("0") +1 )
    //   temp1= parseFloat(temp).toFixed(1).toLocaleString("en-us").toString();
    // }
    return value
      ? value == "-"
        ? prefix + value
        : value == prefix + "-"
        ? value
        : value
            .replaceAll(prefix, "")
            .replaceAll(",", "")
            .match(/^[-$]?\d*\.?\d{0,2}$/)
        ? value.charAt(value.length - 1) == "."
          ? value
          : prefix + temp1.toString()
        : ""
      : "";
  };

  formatNum = (val: string): number => {
    let num;
    if (val) {
      val = val
        .toString()
        .replace("$", "")
        .replace("%", "")
        .split(",")
        .join("");
      num = !isNaN(Number(val)) ? Number(val.split(",").join("")) : 0;
    } else num = 0;
    return num;
  };
  getHomeTopKpis = () => {
    let homeTopKpis = localStorage.getItem(homeKpis.homeTopKpis);
    let result = homeTopKpis ? JSON.parse(homeTopKpis) : [];
    return result;
  };
  getHomeMidKpis = () => {
    let homeMidKpis = localStorage.getItem(homeKpis.homeMidKpis);
    let result = homeMidKpis ? JSON.parse(homeMidKpis) : [];
    return result;
  };
  getHomeBottomKpis = () => {
    let homeBottomKpis = localStorage.getItem(homeKpis.homeBottomKpis);
    let result = homeBottomKpis ? JSON.parse(homeBottomKpis) : [];
    return result;
  };
  timeDelay_Success = () => {
    return 500;
  };
  timeDelay_Delete = () => {
    return 1100;
  };
  timeDelay_Delete_5s = () => {
    return 2000;
  };
  getAge = (birthDate: Date | undefined) => {
    if (birthDate) {
      var today = new Date();
      var b = new Date(birthDate);
      var age_now = today.getFullYear() - b.getFullYear();
      var m = today.getMonth() - b.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < b.getDate())) {
        age_now--;
      }
      return " (" + age_now + ")";
    }
  };
  mapListItem = (list: any) => {
    list &&
      list.length > 0 &&
      list.forEach((element: any) => {
        return (element.label = element.text);
      });
    return list && list.length > 0 ? list : [];
  };
  appendStyle = (styleToAppend: any) => {
    const style = document.createElement("link");
    style.href = styleToAppend;
    style.rel = "stylesheet";
    document.head.appendChild(style);
  };
  disableStyle = (styleName: any) => {
    for (var i = 0; i < document.styleSheets.length; i++) {
      if (document.styleSheets[i] !== null) {
        var href = document.styleSheets[i].href;
        if (href !== null) {
          if (href.includes(styleName)) {
            document.styleSheets[i].disabled = true;
          }
        }
      }
    }
  };
  capitalize = (string: any) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  textCapitalize = (str: any) => {
    if (str !== undefined) {
      let stringArray = str.trim().split(" ");
      return (
        stringArray.length > 0 &&
        stringArray
          .map(
            (s: any) => s.slice(0, 1).toUpperCase() + s.slice(1).toLowerCase()
          )
          .join(" ")
      );
    }
  };
  getCopyRightInfo = () => {
    var d = new Date().getFullYear();
    return `© ${d} Integrity. All rights reserved.`;
  };
  openInNewTab(url: string) {
    window.open(window.location.origin + url, "_blank");
  }
  getContactTypeId = (value: string) => {
    switch (value) {
      case "Email":
        return 1;
      case "Phone Number":
        return 3;
      case "Alternative Point of Contact":
        return 4;
      default:
        return 3;
    }
  };

  capitalizeWords = (sentence: string) => {
    let words = sentence.toLowerCase().split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] =
        words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    }
    return words.join(" ");
  };

  getFileExtension = (fileName: string) => {
    var fileExtension =
      fileName &&
      fileName.split(".") &&
      fileName.split(".").length > 0 &&
      //typeof fileName.split(".").pop == "function" &&
      fileName.split(".").pop() != undefined
        ? fileName.split(".").pop()
        : "";
    return (fileExtension && fileExtension.toLowerCase()) || "";
  };

  getFileTypeByName = (fileName: string) => {
    switch (this.getFileExtension(fileName)) {
      case "xls":
        return "application/vnd.ms-excel";
        break;
      case "xlsx":
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
      case "doc":
        return "application/msword";
        break;
      case "docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        break;
      case "csv":
        return "text/csv";
        break;

      default:
        return "";
        break;
    }
  };
  downloadDocument = (
    base64: string,
    fileName: string,
    actualFileName: string
  ) => {
    let fileDocumentType = this.getFileTypeByName(actualFileName);
    setTimeout(() => {
      var link = document.createElement("a");
      link.download =
        actualFileName !== null && actualFileName !== ""
          ? actualFileName
          : "document.pdf";
      link.href = "data:" + fileDocumentType + ";base64," + base64;
      link.textContent = "Download File";
      link.click();
      document.body.appendChild(link);
      link.remove();
    }, 0);
  };

  hideActionDropDown = () => {
    let showState = true;
    const menuListAllDivs = Array.from(
      document.getElementsByClassName(
        "custom-report-menu-list-email"
      ) as HTMLCollectionOf<HTMLElement>
    );

    const svgDiv = document.getElementById(
      "custom-temp-email-" + "222"
    ) as HTMLElement;
    const menulistDiv = document.getElementById("222");
    if (menulistDiv) {
      for (let i = 0; i < menuListAllDivs.length; i++) {
        menuListAllDivs[i].style.display = "none";
        menulistDiv.style.display =
          menulistDiv.style.display === "block" ? "none" : "none";
        svgDiv.className =
          menulistDiv.style.display === "block"
            ? "custom-report-menuspan"
            : "custom-report-menuspan";
        showState = false;
      }

      const activeClassName = document.querySelector(
        ".custom-report-menu-active"
      );
    } else {
      showState = true;
    }
  };
  openAgentDocumentLink = (agentId: number, docId: number) => {
    try {
      window.open(
        window.location.origin +
          "/agents/" +
          agentId +
          "/pdfViewer/" +
          docId +
          "/" +
          0,
        "_blank"
      );
      return true;
    } catch (e) {
      return false;
    }
  };

  getFileExtensionByContentType = (contentType: string) => {
    var fileType = "";
    switch (contentType) {
      case "text/csv":
        fileType = "csv";
        break;
      case "application/json":
        fileType = "json";
        break;
      case "application/xml":
        fileType = "xml";
        break;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        fileType = "docx";
        break;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        fileType = "xlsx";
        break;
      case "application/zip":
        fileType = "zip";
        break;
      case "application/pdf":
        fileType = "pdf";
        break;
      default:
        fileType = "txt";
        break;
    }
    return fileType;
  };
  downloadDocumentFile = (data: any, fileName: string, contentType: string) => {
    let content_Type = contentType
      ? contentType
      : this.getFileTypeByName(fileName);
    const blob = new Blob([data], { type: content_Type });
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement("a");
    a.download = fileName
      ? fileName
      : "document." + this.getFileExtensionByContentType(content_Type);
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };
  getLabelByValue = (list: any, value: any) => {
    if (list && list.length > 0) {
      var obj = list.filter((i: any) => {
        return i.value == value;
      });
      return obj && obj.length > 0 && obj[0].label;
    }
    return "";
  };

  getDateWithTimeZone = () => {
    var splitwithBraces = new Date().toString().split("(");
    var splitwithSpaces = splitwithBraces[0].trim().toString().split(" ");
    return (
      new Date().toLocaleDateString() +
      ", " +
      new Date().toLocaleTimeString() +
      " " +
      splitwithSpaces[splitwithSpaces.length - 1]
    );
  };
  showSuccessToaster = (
    message: string,
    toasterAutoClose?: number | undefined,
    toasterDelay?: number | undefined
  ) => {
    let autoClose = toasterAutoClose || 2400;
    let delay = toasterDelay || 0;
    let toastId: string = "toast-" + new Date().getTime();
    toast.success(`${message}`, {
      position: toast.POSITION.TOP_RIGHT,
      toastId: toastId,
      delay: delay,
    });
    setTimeout(() => {
      toast.dismiss(toastId);
    }, autoClose);
  };
  getSafeHtml = (text: string | undefined) => {
    return getDangerouslyHtml(text);
  };

  showWarningToaster = (
    message: string,
    toasterAutoClose?: number | undefined,
    toasterDelay?: number | undefined,
    closeToasterButtonTemplate?: any | undefined
  ) => {
    let autoClose = toasterAutoClose || 2400;
    let delay = toasterDelay || 0;
    let toastId: string = "toast-" + new Date().getTime();
    let toastCloseOnClick = closeToasterButtonTemplate ? false : true;
    toast.warning(`${message}`, {
      icon: null,
      position: toast.POSITION.TOP_RIGHT,
      closeOnClick: toastCloseOnClick,
      closeButton: closeToasterButtonTemplate,
      className: "validation-toaster contract",
      draggable: false,
      toastId: toastId,
      delay: delay,
    });
    if (toastCloseOnClick) {
      setTimeout(() => {
        toast.dismiss(toastId);
      }, autoClose);
    }
  };

  /* Horizontal scroll bar starts */
  listenScrollEvent = (updateHorizontalScrollBar: any) => {
    this.attachScrollEvent(window, "scroll", updateHorizontalScrollBar);
    this.attachScrollEvent(window, "resize", updateHorizontalScrollBar);
  };

  attachScrollEvent = (element: any, event: any, callbackFunction: any) => {
    if (element.addEventListener) {
      element.addEventListener(event, callbackFunction, false);
    } else if (element.attachEvent) {
      element.attachEvent("on" + event, callbackFunction);
    }
  };

  detachScrollEvent = (element: any, event: any, callbackFunction: any) => {
    if (element.removeEventListener) {
      element.removeEventListener(event, callbackFunction, false);
    } else if (element.detachEvent) {
      element.detachEvent("on" + event, callbackFunction);
    }
  };
  removeScrollEvent = (updateHorizontalScrollBar: any) => {
    this.detachScrollEvent(window, "scroll", updateHorizontalScrollBar);
    this.detachScrollEvent(window, "resize", updateHorizontalScrollBar);
  };

  visibleY = (el: any) => {
    if (el) {
      var rect = (el && el.getBoundingClientRect()) || null,
        top = rect.top,
        height = rect.height,
        el = el.parentNode;
      return top <= document.documentElement.clientHeight;
    } else {
      return false;
    }
  };

  /* Horizontal scroll bar ends */
}
export default new Utils();
